import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TourAccordion from './TourAccordion';
import {useFilterContext} from '../../controller/context/FilterContext';
import {useDateContext} from "../../controller/context/DateContext";
import {useGuideContext} from "../../controller/context/GuideContext";
import useFRead from "../../controller/firebase/hook/useFRead";
import {useCallback, useEffect, useMemo, useState} from "react";
import {callFunction} from "../../controller/firebase";
import Modal from "@mui/material/Modal";
import {Autocomplete, Card, CardActions, CardContent} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";


export default function TourStack({tours, searchKeyword, productMap, products}) {
  const {defaultLive, mine,} = useFilterContext()
  const {date, dashFormat} = useDateContext();
  const {guide} = useGuideContext();
  const filtered = tours.filter((tour) => tour.teamInfos.people);

  const [openOutsourceProductModal, setOpenOutsourceProductModal] = useState(false);
  const [referenceTour, setReferenceTour] = useState(null);
  const isOutsourced = guide.outsource;

  const handleOpenOutsourceTourCreate = useCallback((referenceTour = null) => {
    setOpenOutsourceProductModal(true);
    setReferenceTour(referenceTour);
  }, []);

  const handleCloseOutsourceTourCreate = useCallback(() => {
    setOpenOutsourceProductModal(false);
    setReferenceTour(null);
  }, []);

  const handleClickOpenOutsourceTourCreate = useCallback(() => {
    if (!defaultLive) {
      alert('실시간 동기화(Sync)를 켜주세요');
      return;
    }
    handleOpenOutsourceTourCreate();
  }, [defaultLive, handleOpenOutsourceTourCreate]);

  return (
      <>
        <Stack
        >
          {
            filtered.length > 0
                ? filtered
                    .map((tour) =>
                        <TourAccordion key={tour.id + tour.snapshotedAt.toString() + defaultLive} products={products}
                                       defaultTour={tour} searchKeyword={searchKeyword} productMap={productMap}
                                       handleOpenOutsourceTourCreate={handleOpenOutsourceTourCreate}
                        />)
                : (
                    <Stack
                        pt={10}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={2}
                    >
                      <Typography
                          variant={'h6'}
                          sx={{fontSize: '1.2rem'}}
                          color={'secondary'}
                          textAlign={'center'}
                      >
                        해당 투어 내용이 없습니다.<br/> No tour on this day.
                      </Typography>
                      {
                        isOutsourced &&
                          <Button variant={'outlined'} onClick={()=>handleOpenOutsourceTourCreate()}>
                            새로운 예약 만들기
                          </Button>
                      }
                    </Stack>
                )
          }
        </Stack>
        {
          openOutsourceProductModal && isOutsourced
              ? (<CreateOutsourceReservationModal
                  open={openOutsourceProductModal}
                  onClose={handleCloseOutsourceTourCreate}
                  date={dashFormat}
                  tour={referenceTour}
              />)
              : null
        }
      </>
  );
}

function CreateOutsourceReservationModal(props) {
  const {open, onClose, productId, tour} = props;
  const {dashFormat} = useDateContext();
  const {guide, guideMap} = useGuideContext();
  const possibleProductIds = guide.managingProducts;


  const {data: nationalities} = useFRead(`nationality`);
  const {data: pickups} = useFRead(`pickup`);
  const {data: products} = useFRead('product');
  const usableProductList = useMemo(() => Object.values(products ?? {}).filter((p) => possibleProductIds.includes(p.id)), [products]);
  const usableProductOptions = useMemo(() => usableProductList.map(up => ({
    id: up.id,
    name: up.name
  })), [usableProductList]);
  const [selectedProductId, setSelectedProductId] = useState(tour?.productId);
  const [loading, setLoading] = useState(false);
  const selectedProduct = usableProductList.find(p => p.id === selectedProductId);
  const pickupPlaceOptions = Object.entries(pickups?.[(selectedProduct?.area ?? 'seoul').toLowerCase()] ?? {})
      .sort((a, b) => a[1].order - b[1].order)
      .map(([key, pickup]) => {
        return ({
          id: pickup.place,
          label: pickup.place,
          name: pickup.place,
          value: pickup.place,
        })
      })

  const nationalityOptions = Object.values(nationalities ?? {}).map(n => ({
    id: n.place,
    name: n.place,
    label: n.place,
    value: n.place,
  }))

  const teamOptions = Object.values(tour?.teams ?? {}).map((team, idx) => ({
    id: team.id,
    name: team._.guides ? team._.guides.map((g) => g.name).join(', ') : `TEAM - ${idx + 1}`,
    label: team._.guides ? team._.guides.map((g) => g.name).join(', ') : `TEAM - ${idx + 1}`,
    value: team.id,
  }));


  const [tempReservation, setTempReservation] = useState({
    date:dashFormat,
    agency: 'KTOS',
    product: selectedProduct?.name,
    productId: selectedProduct?.id,
    tourId: tour?.id || undefined,
    teamId: Object.keys(tour?.teams ?? {})[0] || undefined,
    pickupPlace: '',
    pickupTime: '',
    clientName: '',
    adult: 1,
    kid: 0,
    infant: 0,
    tel: '',
    messenger: '',
    email: '',
    nationality: '',
    memo: '',
    language: 'English',
    option: [],
  })

  const options = (selectedProduct?.option ?? []).filter((option) => {
    // return !(reservation?.option.map((o) => o.option).concat(['Ignore']).includes(option.option));
    return !['Ignore'].includes(option.option);
  }).map((option) => ({
    option: option.option,
    people: tempReservation?.option.find((o) => o.option === option.option)?.people ?? 0
  }));


  const handleCreate = () => {
    setLoading(true)

    if(!tempReservation.productId || !tempReservation.clientName || !tempReservation.pickupPlace){
      return alert('상품, 픽업지, 고객명은 필수 입력정보입니다.');
    }

    callFunction('createOutsourcedReservation', {reservation: tempReservation})
        .then(() => {
          onClose();
          alert("에약이 생성되었습니다.");
        })
        .catch((e) => {
          alert("에약이 생성에 실패했습니다. \r\n" + e.toString());
        })
        .finally(() => {
          setLoading(false)
        })
  }

  const isUpdatable = !!tempReservation.productId && !!tempReservation.pickupPlace && !!tempReservation.clientName && !!tempReservation.adult;

  useEffect(() => {
    if (selectedProduct?.name && selectedProduct.id) {
      setTempReservation((prev) => ({
        ...prev,
        product: selectedProduct.name,
        productId: selectedProduct.id
      }));
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (tour?.productId) {
      setSelectedProductId(tour.productId)
    }
  }, [tour?.productId]);



  return <Modal open={open} onClose={onClose}>
    <Card
        sx={{
          margin: '20px 8px',
          maxHeight: 'calc(100vh - 100px)',
          overflowY: 'scroll'
        }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField fullWidth disabled label={'Date'}
                       value={tempReservation.date}
            />
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
                options={usableProductOptions}
                getOptionLabel={(product) => product.name}
                value={{id: tempReservation?.productId ?? '', name: tempReservation?.product ?? ''}}
                onChange={(_, productOption) => {
                  if (productOption) {
                    setSelectedProductId(productOption.id);
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={'Product'}
                        InputLabelProps={{shrink: true}}
                    />
                )}
            />


          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                options={pickupPlaceOptions}
                getOptionLabel={(option) => option.name}
                value={{
                  id: tempReservation?.pickupPlace ?? '',
                  name: tempReservation?.pickupPlace ?? ''
                }}
                onChange={(_, pickupOption) => {
                  if (pickupOption) {
                    setTempReservation({
                      ...tempReservation,
                      pickupPlace: pickupOption.id,
                    });
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={'PickupPlace'}
                        InputLabelProps={{shrink: true}}
                    />
                )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                options={teamOptions}
                getOptionLabel={(option) => option.name}
                value={(() => {
                  const teamId = tempReservation?.teamId;
                  return teamOptions.find((o) => o.id === teamId);
                })()}
                onChange={(_, nationalityOption) => {
                  if (nationalityOption) {
                    setTempReservation({
                      ...tempReservation,
                      teamId: nationalityOption.id,
                    });
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={'Team(Guide)'}
                        InputLabelProps={{shrink: true}}
                    />
                )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box py={1}>
              <Divider/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={'Client Name'}
                       value={tempReservation.clientName}
                       onChange={(e) => {
                         setTempReservation({
                           ...tempReservation,
                           clientName: e.target.value,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth type={'number'} label={'Adult'}
                       value={tempReservation.adult}
                       onChange={(e) => {
                         const number = Number.parseInt(e.target.value);
                         const adult = number;
                         setTempReservation({
                           ...tempReservation,
                           adult: adult,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth type={'number'} label={'Kid'}
                       value={tempReservation.kid}
                       onChange={(event) => {
                         const number = Number.parseInt(event.target.value);
                         const kid = number;
                         setTempReservation({
                           ...tempReservation,
                           kid: kid,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth type={'number'} label={'Infant'}
                       value={tempReservation.infant}
                       onChange={(event) => {
                         const number = Number.parseInt(event.target.value);
                         const infant = number;
                         setTempReservation({
                           ...tempReservation,
                           infant: infant,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box py={1}>
              <Divider/>
            </Box>
          </Grid>
          {
            options.map((option, idx) => (
                <>
                  {
                    idx > 0
                        ? (
                            <Grid
                                item
                                xs={12}
                                md={2}
                            >
                            </Grid>
                        )
                        : null
                  }
                  <Grid
                      item
                      xs={8}
                      key={option.option}
                  >
                    <TextField
                        fullWidth
                        disabled
                        label={'Option'}
                        value={option.option}
                        InputLabelProps={{shrink: true}}
                        sx={{backgroundColor: 'white'}}
                    />
                  </Grid>
                  <Grid
                      item
                      xs={4}
                      key={option.option + option.people}
                  >
                    <TextField
                        fullWidth
                        type={'number'}
                        label={'People'}
                        value={option.people}
                        InputLabelProps={{shrink: true}}
                        sx={{backgroundColor: 'white'}}
                        onChange={(e) => {
                          const value = Number.parseInt(e.target.value ? e.target.value : '0');
                          if (Number.isNaN(value)) return;
                          const reservationOptions = tempReservation?.option ?? []
                          const scope = reservationOptions.find((o) => {
                            return o.option === option.option
                          });
                          if (scope) {
                            scope.people = value;
                          } else {
                            reservationOptions.push({
                              option: option.option,
                              people: value
                            });
                          }
                          const filtered = reservationOptions.filter((o) => o.people)
                          setTempReservation({
                            ...tempReservation,
                            option: filtered
                          })
                        }}
                    />
                  </Grid>
                </>
            ))
          }
          <Grid item xs={12}>
            <Box py={1}>
              <Divider/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                options={nationalityOptions}
                getOptionLabel={(option) => option.name}
                value={{
                  id: tempReservation?.nationality ?? '',
                  name: tempReservation?.nationality ?? ''
                }}
                onChange={(_, nationalityOption) => {
                  if (nationalityOption) {
                    setTempReservation({
                      ...tempReservation,
                      nationality: nationalityOption.id,
                    });
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={'Nationality'}
                        InputLabelProps={{shrink: true}}
                    />
                )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
                multiple
                options={[
                  {id: 'English', name: 'English'},
                  {id: 'Chinese', name: 'Chinese'},
                  {id: 'Japanese', name: 'Japanese'},
                  {id: 'Korean', name: 'Korean'}]
                }
                value={
                  (tempReservation?.language).split(',').map(s => s.trim()).filter(s => !!s)
                      .map((language) => ({
                        id: language, name: language
                      }))
                }
                onChange={(_, languageOption) => {
                  if (languageOption.filter(o => !!o?.id).length) {
                    setTempReservation({
                      ...tempReservation,
                      language: languageOption.map((o) => o.id).join(', '),
                    });
                  } else {
                    setTempReservation({
                      ...tempReservation,
                      language: "",
                    });
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id.toUpperCase() === value.id.toUpperCase()}
                getOptionLabel={(option) => `${option.id}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={'Lanuage'}
                        InputLabelProps={{shrink: true}}
                    />
                )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box py={1}>
              <Divider/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={'Tel'}
                       onChange={(event) => {
                         setTempReservation({
                           ...tempReservation,
                           tel: event.target.value,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={'Messenger'}
                       onChange={(event) => {
                         setTempReservation({
                           ...tempReservation,
                           messenger: event.target.value,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={'Email'}
                       onChange={(event) => {
                         setTempReservation({
                           ...tempReservation,
                           email: event.target.value,
                         });
                       }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box py={1}>
              <Divider/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
                fullWidth
                multiline
                label={'Memo'}
                onChange={(event) => {
                  setTempReservation({
                    ...tempReservation,
                    memo: event.target.value,
                  });
                }}
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions sx={{
        backgroundColor: 'white',
        position: 'sticky',
        bottom: 0,
        left: 0,
        padding: 2,
        margin: 0,
        zIndex: 1,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button fullWidth onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button fullWidth
                    disabled={!isUpdatable || loading}
                    variant={'contained'}
                    onClick={handleCreate}>
              {
                loading
                    ? <CircularProgress size={24}/>
                    : 'CREATE'
              }
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  </Modal>
}
