import {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetail from '@mui/material/AccordionDetails';

import Checkbox from '@mui/material/Checkbox';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabled from '@mui/icons-material/SyncDisabled';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';

import useFListen from '../../controller/firebase/hook/useFListen';
import useFRead from "../../controller/firebase/hook/useFRead";

import {useFilterContext} from '../../controller/context/FilterContext';
import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';

import Tour from '../../model/Tour';
import TeamPart from './TeamPart';

const tourValtoTour = (basePath) => (tourVal, tourId, snapshot) => {
  if (!tourVal) return {};
  return new Tour(tourVal, tourId, basePath);
};

export default function TourAccordion({
                                        defaultTour, searchKeyword, productMap, products,
                                        handleOpenOutsourceTourCreate,
                                      }) {
  const navigate = useNavigate();

  const {guide, guideMap} = useGuideContext();
  const isMyTour = defaultTour?.teamInfos.guideSet.has(guide?.id) ?? false;

  const {date, dashFormat, setDate} = useDateContext();
  const {pickupOption, defaultLive, mine} = useFilterContext();

  const [live, setLive] = useState(defaultLive || isMyTour || (guide.outsource) && guide.managingProducts?.includes(defaultTour.productId));
  const [loadingCreateChat, setLoadingCreateChat] = useState(false);
  const [chatId, setChatId] = useState(null);

  const {data: tour} = useFListen(live, defaultTour.path, tourValtoTour(defaultTour.basePath), defaultTour);
  const isOutsourced = live && (guide.outsource) && guide.managingProducts?.includes((tour ?? defaultTour).productId);

  const handleLiveChecked = useCallback((e, value) => {
    setLive(e.target.checked);
  }, []);
  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const {product, productId, teams = {}} = tour ?? defaultTour;
  const teamList = Object.values(teams);
  const guides = teamList
      .map((t) => t.guides)
      .flat(1)
      .filter((g) => !!g)
      .map((gId) => {
        const guide = guideMap?.get(gId);
        return guide ? {name: guide.name, id: guide.id, nameEn: guide.nameEn, type: 'Guide'} : null;
      })
      .filter((g) => !!g);


  const handleOpenCreateTour = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (!live) {
      alert('실시간 동기화(Sync)를 켜주세요');
      return;
    }

    handleOpenOutsourceTourCreate(tour);
  }


  return (
      <>
        <Accordion
            TransitionProps={{
              timeout: 0,
            }}
            sx={{
              margin: '0px !important',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
            defaultExpanded={mine && isMyTour}
            defaultChecked={mine && isMyTour}
        >
          <AccordionSummary
              sx={{
                position: 'sticky',
                top: '56px',
                left: 'auto',
                zIndex: 1000,
                background: 'white',
                minHeight: '56px !important',
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0px !important',
                },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  marginRight: -0.5,
                },
              }}
              expandIcon={<ExpandMoreIcon/>}
          >
            <Checkbox
                sx={
                  {
                    // marginLeft:'-14px', //edge start adjust
                  }
                }
                size={'small'}
                edge={'start'}
                icon={<SyncDisabled/>}
                checkedIcon={<SyncIcon/>}
                checked={live || isMyTour}
                disabled={isMyTour}
                onChange={handleLiveChecked}
                onClick={stopPropagation}
            />
            <Typography
                variant={'h6'}
                sx={(theme) => ({
                  color: isMyTour ? theme.palette.primary.main : theme.palette.text.primary,
                  fontSize: '1.2rem',
                })}
            >
              {productMap?.get(tour.productId)?.name ?? tour.product}
            </Typography>
            {
              isOutsourced
                  ? (
                      <IconButton disabled={!isOutsourced}>
                        <AddIcon onClick={handleOpenCreateTour}/>
                      </IconButton>
                  )
                  : null
            }
            {/*{guide.admin || isMyTour ? (*/}
            {/*    <IconButton*/}
            {/*        onClick={(e) => {*/}
            {/*            e.stopPropagation();*/}
            {/*            e.preventDefault();*/}

            {/*            if (!guides || guides.length === 0) return;*/}
            {/*            if (!window?.confirm('채팅을 여시겠습니까?')) return;*/}
            {/*            async function recallChat() {*/}
            {/*                setLoadingCreateChat(true);*/}
            {/*                if (chatId) return chatId;*/}
            {/*                const chat = await callFunction('recallChat', {*/}
            {/*                    category: 'TOUR',*/}
            {/*                    cId: `${dashFormat}:${productId}`,*/}
            {/*                    title: `${dashFormat}:${product}`,*/}
            {/*                    participants: [*/}
            {/*                        ...guides,*/}
            {/*                        ...OPERATOR,*/}
            {/*                        ...(productId.toLowerCase().includes('ski') ? ACCOUNTANTS : []),*/}
            {/*                        {*/}
            {/*                            id: guide?.uid,*/}
            {/*                            name: guide?.name,*/}
            {/*                            nameEn: guide?.nameEn,*/}
            {/*                            type: guide?.level > 1 ? 'Guide' : 'Operator',*/}
            {/*                        },*/}
            {/*                    ],*/}
            {/*                    tour: {*/}
            {/*                        date: dashFormat,*/}
            {/*                        productId: productId,*/}
            {/*                        team: null,*/}
            {/*                    },*/}
            {/*                });*/}
            {/*                if (chat && chat.id) {*/}
            {/*                    setChatId(chat.id);*/}
            {/*                }*/}
            {/*                return chat.id;*/}
            {/*            }*/}

            {/*            recallChat()*/}
            {/*                .then((chatId) => {*/}
            {/*                    try {*/}
            {/*                        if (!chatId) return alert('적절한 채팅방을 찾을 수 없습니다.');*/}
            {/*                        navigate(`/chat/rooms/${chatId}`);*/}
            {/*                    } catch (e) {*/}
            {/*                        alert('채팅방을 열 수 없습니다.');*/}
            {/*                    }*/}
            {/*                })*/}
            {/*                .catch(console.error)*/}
            {/*                .finally(() => {*/}
            {/*                    setLoadingCreateChat(false);*/}
            {/*                });*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        {loadingCreateChat ? (*/}
            {/*            <CircularProgress*/}
            {/*                size={'small'}*/}
            {/*                sx={{*/}
            {/*                    width: '20px',*/}
            {/*                    height: '20px',*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        ) : (*/}
            {/*            <ChatIcon*/}
            {/*                sx={{*/}
            {/*                    width: '20px',*/}
            {/*                    height: '20px',*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    </IconButton>*/}
            {/*) : null}*/}
          </AccordionSummary>

          <AccordionDetail
              sx={{
                p: 0,
              }}
          >
            <List disablePadding>
              {tour.teamList
                  .filter(
                      (team) =>
                          (mine ? team.guides.includes(guide.id) : true) &&
                          (pickupOption === 'all' ||
                              team.pickupPlaces.map((p) => p.key.toLowerCase()).includes(pickupOption)) &&
                          (!searchKeyword ||
                              Object.values(team.reservations).filter((r) =>
                                  r.toString().toLowerCase().includes(searchKeyword.toLowerCase()),
                              ).length > 0),
                  )
                  .map((team, idx) => {
                    return (
                        <TeamPart
                            idx={idx}
                            product={product}
                            products={products}
                            productId={productId}
                            key={team.id}
                            team={team}
                            tour={tour}
                            live={live}
                            isMyTour={isMyTour}
                            searchKeyword={searchKeyword}
                        />
                    );
                  })}
            </List>
          </AccordionDetail>
        </Accordion>
      </>
  );
}
